<router-outlet></router-outlet>

<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3>Réseaux sociaux</h3>
        <a href="https://www.facebook.com/rencontrescontemporaines/?ref=br_rs">
          <img src="/assets/images/facebook.png" alt="facebook" class="rs-icon">
        </a>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <h3>A propos</h3>
        Développé et maintenu par <a href="https://arsenelapostolet.fr">Arsène Lapostolet</a><br><br>
        Construit avec :<br><br>
        <img class="tech-icon" src="https://angular.io/assets/images/logos/angular/angular.png"/>
        <img class="tech-icon"
             src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/.NET_Core_Logo.svg/1024px-.NET_Core_Logo.svg.png"/>
      </div>
    </div>

  </div>
  <div class="legal-mentions"><a href="assets/docs/mentions-legales.pdf">Mentions légales</a></div>
</footer>
