
<mat-form-field appearance="fill">
  <input matInput type="datetime-local"  [(ngModel)]="atelier.date" placeholder="start date">
</mat-form-field>

  <mat-form-field  appearance="fill">
    <mat-label>Commentaire</mat-label>
    <input type="text" name="title" [(ngModel)]="atelier.comment" matInput>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="onSubmit()">Valider</button>

