
<mat-dialog-content>
<div class="container">
  <h2>{{modelTitle}}</h2>

  <div class="row">
    <div class="col-md-6">

      <mat-form-field  appearance="fill">
        <mat-label>Titre</mat-label>
        <input name="title" [(ngModel)]="concert.title" matInput>
      </mat-form-field>

      <mat-form-field  appearance="fill">
        <mat-label>Chosir une date</mat-label>
        <input matInput [(ngModel)]="concert.dateTime" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Ajouter une ou plusieurs heures</mat-label>
        <mat-chip-grid #chipList aria-label="Fruit selection">
          <mat-chip-option *ngFor="let hour of getHoursWithoutSeconds(concert)" [selectable]="true"
                    [removable]="true" (removed)="remove(hour)">
            {{hour}}
            <mat-icon matChipRemove (click)="handleClickDeleteHour(hour)">cancel</mat-icon>
          </mat-chip-option>
          <input placeholder="Nouvelle heure au format HH:MM..."
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="add($event)">
        </mat-chip-grid>
      </mat-form-field>

      <mat-label>Date sûre</mat-label><br>
      <mat-slide-toggle name="dateSure" [(ngModel)]="concert.dateSure" color="primary">{{concert.dateSure ? 'Sûre' : 'Pas Sûre'}}</mat-slide-toggle>
      <br>



      <mat-form-field  appearance="fill">
        <mat-label>Emplacement</mat-label>
        <input name="location" [(ngModel)]="concert.location" matInput>
      </mat-form-field>

      <mat-label>Lieu sûre</mat-label><br>
      <mat-slide-toggle name="locationSure" [(ngModel)]="concert.locationSure" color="primary">{{concert.dateSure ? 'Sûre' : 'Pas Sûre'}}</mat-slide-toggle>
      <br>

      <mat-form-field  appearance="fill">
        <mat-label>Artiste</mat-label>
        <input name="artist" [(ngModel)]="concert.artist" matInput>
      </mat-form-field>

    </div>
    <div class="col-md-6">
      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3"
                  cdkAutosizeMaxRows="10"
                  name="description"
                  [(ngModel)]="concert.description"
        ></textarea>
      </mat-form-field>


      <mat-label>Image</mat-label><br>
      <input type="file" (change)="onImageFileChange($event.target.files)">
      <br><br>
      <mat-label>Flyer</mat-label><br>
      <input type="file" (change)="onFlyerFileChange($event.target.files)">

    </div>
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="!loading" mat-raised-button color="primary" mat-dialog-close="true" (click)="onSubmit()">Valider
  </button>
</mat-dialog-actions>
<mat-spinner *ngIf="loading" diameter="16"></mat-spinner>
